.modal-close-btn{
    position:absolute;
    top:5px;
    cursor: pointer;
    right:5px;
    color:#b63838;
    width:15px;
    height:15px;
    border:solid 2px #b63838;
    border-radius: 20px;
    display: flex;
    font-size: 14px !important;
    align-items:center;
    justify-content:center;
    padding:7px !important;
}

.parent-modal-title{
    margin-bottom:20px;
}