.teacher-avatar{
    width:60px;
    height: 60px;
    border-radius: 40px;
    border:solid 1px rgba(0, 0, 0, 0.358);
}

.avatar-wrapper h3, .avatar-wrapper p{
    margin:0;
}

.avatar-wrapper img{
    margin-right:15px;
}

.avatar-wrapper{
    display: flex;
    align-items:center;
    padding:10px;
}

.shop-header{
    position: relative;
}

.info-float{
    position: absolute !important;
    bottom:0;
    left:0;
}

.info-bg-transparent{
    background:rgba(0, 0, 0, 0.523);
    color:white
}

.info-bg-solid{
    background: rgb(3, 99, 93);
    color: white;
}