
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* SLick Slider Home Nav Icons CSS */
button.slick-arrow.slick-next::before, button.slick-arrow.slick-prev::before 
{
  color: rebeccapurple;
  font-size: 38px;
}

ul.slick-dots li {
  margin: 0;
}

ul.slick-dots li button:before {
  font-size: 10px;
}


input#outlined-basic {
  box-shadow: none;
  border: none;
  outline: 0 !important;
  font-size: 16px;
}

.pdfLow canvas.react-pdf__Page__canvas {
  /* width: 420px !important; */
  height: auto !important;
}

.slick-next {
  right: -35px;
}

@media screen and (max-width: 480px) {
  .slick-track {
    /* transform: translate3d(0, 0px, 0px) !important; */
  }
  
  .slick-slide.slick-current > div > div > div {
    margin: 0 !important;
  }
  
  .slick-next {
    right: -7px;
  }
}

@media screen and (max-width: 770px) {
  
  .slick-slide.slick-current > div > div > div {
    margin: 0 !important;
  }
  
  .slick-next {
    right: -25px;
  }
}

@media screen and (max-width: 1024px) {
  
  .slick-slide.slick-current > div > div > div {
    margin: 0 !important;
  }
  
  .slick-next {
    right: -25px;
  }
}

@media screen and (max-width: 1224px) {
  
  .slick-slide.slick-current > div > div > div {
    margin: 0 !important;
  }
  
  .slick-next {
    right: -25px;
  }
}


.slick-next, .slick-prev{
  width: auto !important;
  height: auto !important;;
}