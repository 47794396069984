.form-container{
    height: 100%;
}
.reg-form{
    background:#fff;
    border:solid;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 45%);
    border-width: 1.5px;
    border-radius:10px;
    height:70%;
    position: relative;
}
.form-heading{
    text-align: center;
    margin: 4rem 0 !important;
    color:#FA9C24;
}
.submit-btn{
    background: #FA9C24 !important;
    color:white !important;
    width:120px !important;
    /* height:45px !important; */
    justify-self: end;
}
.disabled-btn{
    background: #d39646 !important;
    color:white !important;
    width:120px !important;
    height:45px !important;
    justify-self: end;
}
.submit-btn:hover{
    background: #e18d20 !important;
    color:white !important;
    width:120px !important;
    /* height:45px !important; */
    justify-self: end;
}
.submit-btn-disabled{
    background-color: #c9c9c9 !important;
    color:rgb(65, 65, 65) !important;
    width:150px !important;
    /* height:45px !important; */
    justify-self: end;
}

.full-width{
    width:100% !important;
}

.mt-5{
    margin-top:30px !important;
}

.steps{
    margin-top:20px;
}
.step-wrapper{
    position:absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: start;
}
.current-step{
    font-size:34px;
    font-weight: 700;
    line-height: 0.8;
}
.total-step{
    font-size:16px
}
.faceImage{
    width:100px;
    height:100px
}
.slide-item{
    width:100%;
    padding:0 20px;
    margin-top:30px;
    padding-bottom:25px;
}
@media only screen and (max-width: 750px){
	/*Big smartphones [426px -> 600px]*/
    .slide-item{
        width:100%;
        padding:0px;
        margin-top:0px;
        padding-bottom:10px;
    }
}
.profile-picture-button{
    width:130px;
    height:130px;
    border-radius: 100px !important;
    background:rgb(227, 227, 227) !important;
    border:dashed !important;
    border-width: 1px !important;
    overflow: hidden;
    padding: 0 !important;
}
.upload-picture-button{
    width:100%;
    height:170px;
    border-radius: 10px !important;
    background:rgb(242, 242, 242) !important;
    border:dashed !important;
    border-width: 1px !important;
    overflow: hidden;
    padding: 0 !important;
}
.profile-picture-button-modal{
    width:100%;
    height:100%;
    overflow: hidden;
    padding: 0 !important;
}
.wrapper-modal{
    width:300px;
    height:300px;
}
.picture-icon{
    font-size:30px !important;
    color:rgb(155, 155, 155)
}

.gender-check{
    position:absolute;
    top:-8px;
    left:5px;
    color:white;
    background:green;
    width:13px;
    height:13px;
    font-size: 14px !important;
    padding:3px;
    border-radius: 20px;
}

.female-checked{
    background:rgb(223, 56, 134) !important;
    color:white !important;
}
.male-checked{
    background:rgb(56, 165, 223) !important;
    color:white !important;
}
.qualification{
    margin-bottom:0px !important;
}

.tags{
    margin-bottom: 10px !important;    
    margin-top: 15px !important;
}

.left-section{
    padding-right: 20px;
}

.error-message, .errors{
    color:red;
}
.profile-wrapper{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 1rem;
    height: 100%;
    justify-content:center;
}

.success-card{
    box-shadow: 4px 4px 10px rgb(0 0 0 / 45%);
    background-color:white !important;
    border-radius:20px;
    padding:15px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    padding-bottom:30px;
}

.check-wrapper{
    width:70px;
    height:70px;
    background:#00c0e2;
    border-radius:100px;
    display:flex;
    align-items:center;
    justify-content: center;
    margin:1rem 0;

}

.check-icon{
    font-size:35px !important;
    color:white;
}

.ok-btn{
    background: #fa9c24 !important;
    color: white !important;
    margin-top:20px !important;
}

.ok-link{
    text-decoration: none;
    color:white;
}

.success-wrapper{
    height:90vh;
    /* padding: 7.5rem 0; */
    background-color: #11444d;
}

.upload-document-button{
    width:100%;
    border:dashed !important;
    border-color:rgb(176, 176, 176) !important;
    border-width:2px !important;
    height:250px;
}

.new-document-button{
    width:100%;
    border:solid !important;
    background:#1A95AC !important;
    border-color:#14798a !important;
    border-width:2px !important;
    height:50px;
}
.new-document-button p,.new-document-button .upload-icon{
    color:white !important;
}

.qualification-inner{
    display: flex;
    align-items: center;
}

.qualification-inner p{
    font-size:14px !important;
    margin:0 !important;
    color:rgb(174, 174, 174);
}

.upload-icon{
    font-size:26px !important;
    color:rgb(193, 193, 193);
    margin-right:10px;
}

.update-btn-wrapper{
    padding: 0 20px !important;
}

.tag-header {
    background: #e9e9e9;
    border-radius: 7px;
    margin-top: 20px !important;
    margin-bottom: 5px !important;
    padding: 5px 8px !important;
}

.tag-header.form {
    background: #e9e9e9;
    border-radius: 7px;
    margin-top: 15px !important;
    margin-bottom: 5px !important;
    padding: 5px 8px !important;
}

.form-field{
    margin-top: 7px !important;
}

.file-wrapper{
    padding:10px;
    margin-right: 5px;
    margin-bottom: 5px !important;
    display: flex;
    align-items:center;
    border-radius: 10px;
    
}

.files-wrapper{
    height: 250px;
    display: flex;
    align-items:flex-start;
    overflow-y: scroll;
    flex-direction: column;
    justify-self: start;
}

.file-wrapper:hover{
    background: rgb(238, 238, 238);
}
.file-wrapper img{
    width:10%;
    margin-right:10px;
}

.name-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}
.name-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.name-wrapper .delete-icon {
    font-size: 14px;
    background: #c74343;
    width: 16px !important;
    height: 16px !important;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 2px;
    cursor: pointer;
    margin-left:5px
}
.name-wrapper .edit-icon {
    font-size: 14px;
    background: #edb31f;
    width: 16px !important;
    height: 16px !important;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 2px;
    cursor: pointer;
    margin-left:20px
}
.view-icon {
    font-size: 14px !important;
    background: #3f77bc;
    width: 24px !important;
    height: 24px !important;
    border-radius: 20px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: white;
    padding: 0px;
    cursor: pointer;
    margin-left:5px
}

.no-btn{
    background:rgb(205, 205, 205) !important;
    margin-right:10px !important;
}
.yes-btn{
    background:rgb(172, 67, 67) !important;
    margin-left:10px !important;
    color:white !important;
}

.form-error{
    color:rgb(227, 42, 42);
}

.file-wrapper{
    background-color: rgb(206, 206, 206) !important;
}

.file-header-wrapper{
    background-color: rgb(242, 242, 242) !important;
    padding:5px;
    border-radius: 5px;
}
.file-header-wrapper span{
    font-size:14px
}

.schedule{
    display:flex;
    align-items:center;
}
.schedule span{
    margin-right:10px
}
.schedule strong{
    margin-left:5px
}

.time-details{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background:rgb(218, 218, 218);
    padding:10px;
}

.time-details h4{
    margin-top:5px
}

.save-btn{
    background:rgb(52, 136, 52) !important;
    color:white !important;
    margin-left:10px !important;
}

.ck-editor__editable {
    min-height: 200px !important;
}

.text-dark{
    color:black;
}

.cover-photo-section{
    width: 100%;
        margin-bottom: 20px !important;
        background: #f1f1f1 !important;
        border: dashed !important;
        border-width: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        height:215px !important;
}
.cover-photo-active{
    width: 100%;
        margin-bottom: 20px !important;
        background: #f1f1f1 !important;
        border: solid 1px rgb(210, 210, 210) !important;
        /* border-width: 1px !important; */
        overflow: hidden !important;
        padding: 0 !important;
        height:auto !important;
}


.profile-picture-button .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
    width: 100% !important;
    height: 100% !important;
}


.test-wrapper{
    position: relative;
    width:100%;
    height:100%;
}

.upload-btn {
    margin-top:10px !important;
    width:100%;
    background:rgb(227, 227, 227) !important;
}

.upload-btn span{
    font-size: 11px !important;
}
.upload-btn .material-icons{
    font-size: 16px !important;
    margin-right: 10px;
}

.profile-pic-wrapper{
    height:150px;
    width:150px;
}

.profile-pic-wrapper .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
    width: 90% !important;
    height: 90% !important;
}

.profile-pic .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound.reactEasyCrop_CropAreaGrid {
    width: 80% !important;
    height: 80% !important;
}

.profile-pic .crop-container {
    width: 250px !important;
    height: 250px !important;
}
.new-pic .profile-picture-button {
    width: 200px !important;
    height: 200px !important;
    border-radius: 300px !important;
}

.confirm-btn-circle{
    width:40px !important;
    height:40px !important;
    min-width: 0 !important;
    padding:15px !important;
    border-radius:50px !important;
    background-color:rgb(6, 154, 85) !important;
    color:white !important;
}

.close-btn-circle{
    width:20px !important;
    height: 20px !important;
    min-width: 0 !important;
    padding:2px !important;
    border-radius: 20px !important;
    color:rgb(198, 198, 198) !important;
    font-size:10px !important;
    position: absolute !important;
    top:10px !important;
    right: 7px !important;
}

.modal-heading{
    position: relative;
    width:100% !important;
}

.profile-picture-btn{
    background:rgb(223, 223, 223) !important;
}

.refresh-passcode-icon{
    background:rgb(227, 227, 227);
    color:rgb(27, 107, 154);
    padding:5px;
    border-radius: 3px;
    cursor: pointer;
}

.blue-icon{
    background:rgb(11, 120, 160);
    color:white;
}
.red-icon{
    background:rgb(173, 96, 14);
    color:white;
}
.green-icon{
    background:rgb(11, 150, 113);
    color:white !important;
}

.group-icon{
    font-size: 1.1em !important;
    margin: 0 2px;
}

.date-picker input{
    padding: 8px !important;
}

.switch-wrapper svg{
    display:none !important;
}

.slide-item .MuiFormControl-root {
    margin-top: 5px;
}