.cover-picture-button{
    width:425px;
    height: 250px;
    border:dashed 1px gray;
    border-radius: 10px;
}

.cover-picture-button{
    width:430px;
    height:330px;
    /* border-radius: 100px !important; */
    background:rgb(227, 227, 227) !important;
    border:dashed !important;
    border-width: 1px !important;
    overflow: hidden;
    padding: 0 !important;
}
.cover-picture-button-modal{
    width:100%;
    height:100%;
    overflow: hidden;
    padding: 0 !important;
}


.cover-pic-wrapper .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
    width: 90% !important;
    height: 90% !important;
}

.cover-pic .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound.reactEasyCrop_CropAreaGrid {
    width: 80% !important;
    height: 80% !important;
}

.cover-pic .crop-container {
    width: 450px !important;
    height: 350px !important;
}
.cover-pic .cover-picture-button {
    width: 400px !important;
    height: 300px !important;
    border-radius: 10px !important;
}

.cover-picture-btn{
    background:rgb(232, 232, 232) !important;
}

.mobile.cover-pic .crop-container {
    width: auto !important;
    height: 350px !important;
}