.loading-wrapper{
    width:100%;
    height:100vh;
    position:fixed;
    top: 0 !important;
    left:0;
    background: white;
    z-index: 300;
    display:flex;
    align-items:center;
    justify-content: center;
}

.ring-loader {
    position: absolute;
    width: 100%;
    left: 0;
    background: white;
    height: 70%;
    z-index: 3;
    display: flex;
    align-items: center;
    bottom: 0;
}
.ring-loader-full {
    position: absolute;
    width: 100%;
    left: 0;
    background: white;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center !important;
    bottom: 0;
}