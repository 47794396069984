.admin-top-bar{
    background:white;
    padding:10px;
    border-radius: 10px;
    margin:10px auto;
    width:97%
}
.admin-nav-btn{
    display:flex;
    flex-direction: column;
    text-decoration: none !important;
    margin:10px;
    border:solid 1px rgb(215, 215, 215);
    border-radius:10px;
    padding:10px;
    color:#343434;
    align-items: center;
}

.admin-nav-btn p{
    margin-top:0px;
    margin-bottom: 0;
    color:rgb(99, 99, 99);
    font-size:12px
}
.admin-nav-btn span{
    font-size:34px !important;
}

.nav-link{
    width:7%;
    text-align: center;
}

a.admin-nav-btn.is-active {
    background: #ebf9fb;
    border-color: #bce9f1;
    border-width: 2px;
}

a.admin-nav-btn:hover {
    background: #ebf9fb;
    border-color: #bce9f1;
    border-width: 2px;
}

a.admin-nav-btn button{
    background: transparent !important;
}