.header {
    margin: 0px 20px;
    cursor: pointer;
    margin-top:20px;
    display:flex;
    align-items: center;
}


.totalComments {
    color: rgb(143, 143, 143);
}

.backButton {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 5px 15px;
    cursor: pointer;
}

.star {
    color: #16bcac;
}

.commentList {
    height: 80vh;
    margin: 20px 10px;

}

.progressBar .MuiLinearProgress-bar {
    background: #18b1a2;
}

.pp {
    border-radius: 50%;
}

.userName {
    display: inline-block;
    margin-left: 10px;
}

h4,
.Comment_date {
    margin: 0;
}

p {
    font-size: 16px;
    line-height: 20px;
}

.sComment {
    width: 80%;
    margin: 20px 20px;
}

.commentList {
    overflow: scroll;
    overflow-x: hidden;
    background: #f2f2f2;
    border-radius: 10px;
    padding: 15px;
}

.more {
    font-weight: 400;
    color: rgb(7, 163, 111);
}
.rating_box{
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rating_box> * {
    
    margin: 0 5px;
}
.progressBar{
    width:60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progressBar span{
    font-size: 12px;
    margin-left:5px;
    display: block !important;
}
.rating {
    margin: 40px 100px;
}
.star-wrapper{
    display:flex;
    align-items: center;
}

.star-wrapper span{
    margin:0 5px
}