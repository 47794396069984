.tab-single{
    color:white !important;
}

.tabls-panel button span{
    color:white;
}



.get-access-btn{
    position:absolute;
    top:0;
    width:99%;
    left: 0;
    padding-top:10px;
    padding-right:10px;
    display: flex;
    justify-content: flex-end;
    z-index: 5;
}
.slide-in-btn{
    display: flex;
    justify-content: end;
}

.get-access-btn-floating{
background:orange !important;
padding:10px;
color:white !important;
}

.hiddenPrint{
    display: none;
}



@media (max-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ 
}
@media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
    .hiddenPrint{
    display: block !important;
}}
@media (max-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    .hiddenPrint{
        display: block !important;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
      .hiddenPrint{
          display: block !important;
      }

}

