.shop-avatar{
    width:80px;
    height:80px;
    border-radius: 80px;
}

.shops-wrapper{
    background:white;
    border-radius: 20px;  
    padding:30px; 
}

.shops-container{
    margin:30px 0;
}

.single-shop-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background:rgb(240, 253, 255);
    margin:10px 15px;
    padding:35px 10px;
    border-radius: 10px;
    cursor: pointer;
    border:solid 2px white;
}

.single-shop-wrapper:hover{
    border:solid 2px rgb(220, 247, 255);
}

.shop-name{
    margin-top:10px !important;
    text-decoration: none !important;
    color:rgb(33, 33, 33);
    text-align: center;
}

.page-icon{
    margin-right:10px;
    font-size:35px !important;
}

.page-header h1{
    display: flex;
    align-items:center;
}

.shop-username{
    font-weight: 100 !important;
    font-size:11px !important;
    color:rgb(70, 70, 70);
    text-decoration: none !important;
}

.link-text{
    text-decoration: none !important;
}
@media only screen and (max-width:480px){
    .shops-wrapper{
        padding:5px !important;
        border-radius: 10px;  
    }
    .single-shop-wrapper{
        margin:3px;
        padding:35px 10px;
        border-radius: 10px;
    }
    .shops-container{
        margin-top:5px;
    }
}

.sort-button{
    border-radius:30px;
    min-width:20px;
    width:35px !important;
    height:35px !important;
    background-color:white !important;
    display: flex;
    align-items: center;
    justify-content:center;
}