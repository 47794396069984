.body-wrapper{
    background: rgb(213, 237, 255);
    min-height: 100vh;
    width:100%;
    position:fixed;
    top: 0 !important;
    left:0;
    z-index: 300;
    display:flex;
    align-items:center;
    justify-content: center;
}

h2{
    font-family: 'Arial' !important;
    font-weight: 300 !important;
    margin-bottom:10px;
}

h5{
    font-family: 'Arial' !important;
    font-weight: 300 !important;
    margin:0;
    color:rgb(150, 150, 150);
    margin-bottom: 20px;
}

.passcode-section{
    background:white;
    border-radius: 15px;
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lock-wrapper{
    width:100px;
    height: 100px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:#E8F5FF;
}
.lock-wrapper-success{
    width:100px;
    height: 100px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:#E8FFEE;
}
.lock-wrapper-danger{
    width:100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:#FFEEF1;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-wrapper input {
    /* padding: 5px;
    width: 45px;
    text-align: center;
    font-size: 3rem;
    height: 45px;
    margin: 5px;
    border-radius: 7px; */
    box-shadow: none;
    text-shadow: none;
    border: solid 1px #2592e3;
    text-transform: uppercase;
}

.text-danger{
    color:#D84140;
    font-family: 'Arial' !important;
    font-weight: 300 !important;
}
.text-success{
    color:#1FBC57;
    font-family: 'Arial' !important;
    font-weight: 300 !important;
}
.text-secondary{
    color:rgb(114, 114, 114);
    font-family: 'Arial' !important;
    font-weight: 300 !important;
}

.share-icons{
    width:100px;
    height:100px;
    border-radius: 100px;
    padding:8px;
    cursor: pointer;
    font-size:2rem !important;
    margin-bottom: 10px;
}
.whats-icons{
    background:rgb(11, 160, 78);
    color:white;
}
.link-icons{
    background:rgb(11, 120, 160);
    color:white;
}
.key-icons{
    background:rgb(173, 96, 14);
    color:white;
}
.copy-icons{
    background:rgb(11, 150, 113);
    color:white;
}
.close-modal{
    position: absolute;
    top:10px;
    right:10px;
    color:rgb(169, 33, 33);
    font-size:14px !important;
    background:rgb(231, 230, 230);
    width:20px;
    height:20px;
    padding:2px;
    cursor:pointer;
    border-radius: 20px;
}

.url-input{
    background:#f2f2f2;
    border: solid 1px !important;
    border-color:#e4e4e4 !important;
    width:95%;
    font-size:14px;
    color:rgb(145, 145, 145);
    padding:15px 10px;
    border-radius: 5px;
    margin-bottom: 10px !important;
    overflow: hidden;
}

/* #productlink,#productcode{
    display: none;
} */


@media only screen and (max-width: 600px) {
    .input-wrapper div div {
        display: flex;
    }
    
    .input-wrapper input {
        width: 13% !important;
        height: auto !important;
    }
    .input-wrapper input {
        font-size: 1.9rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .input-wrapper div div {
        display: flex;
    }
    
    .input-wrapper input {
        width: 12% !important;
        height: auto !important;
    }
    .input-wrapper input {
        font-size: 1.4rem !important;
    }
}

