.drop-zone-wrapper{
    border:dashed 1px gray;
    border-radius: 10px;
    padding:20px;
    margin:20px 10px;
    text-align: center;
    cursor: pointer;
}

.drop-zone-wrapper:hover{
    background:rgb(245, 245, 245);
}

.upload{
    font-size: 4em !important;
    color:rgb(103, 165, 220);
}

h3{
    margin:5px 0 !important;
    color:gray;
}
h5{
    margin:0 !important
}