.slider-item{
    border-radius: 0 !important;
    background-size:cover;
}
.slider-item-mobile{
    height:30vh;
}
.video-wrapper{
    display: flex;
    align-items:center;
    justify-content:center;
    background: rgb(43, 43, 43) !important;
    
}
.slider-item-ipad{
    height:50vh !important;
}
.slider-item-laptop{
    height:50vh;
}

.backdrop{
    padding:40px;
    margin:0 !important;
    background: rgba(0, 0, 0, 0.317);
}

.volumne-btn{
    width:30px !important;
    height:30px !important;
    background:black;
    color:white;
    display: flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    top:25px;
    right:15px;
    z-index: 30;
    border-radius:20px;
    font-size:20px !important;
    cursor: pointer;
}

