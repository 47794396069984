.banner-wrapper{
    height:550px;
    background-size: cover;
    /* background-blend-mode: saturation */
}

.banner-wrapper h2{
    font-weight: 500;
    font-size:38px;
}

.banner-wrapper h6,.banner-wrapper h5, .banner-wrapper h4, .banner-wrapper h3, .banner-wrapper h2, .banner-wrapper h1{
color:white
}

.action-button{
    background: rgb(225, 138, 9) !important;
    color: white !important;
    padding: 15px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    margin-top: 20px !important;
}

.hero-info-wrapper{
    background:rgba(0, 0, 0, 0.331);
    margin-left: 0px;
    padding:20px;
    width:100%;
}

.fixed-course-info{
    background:rgb(8, 28, 31);
    padding-right:10px;
}
.fixed-teacher-info{
    background:rgb(12, 40, 45);
    padding-right:10px;
}

.course-info{
    background:rgb(232, 232, 232);
}

.course-info-single{
    padding:10px;
    border-bottom:solid;
    border-color:rgb(210, 210, 210);
    border-width:1px;
    margin-bottom: 10px;
}

.social-icons-wrapper{
    margin-top:10px;
    margin-bottom:8px;
}

.course-info{
    padding:10px 30px;
}

.course-info-single h5{
    margin:0;
    font-size:18px;
    font-weight: 500;

}

.icon{
    margin-right: 10px;
    font-size:26px !important;
}

.green-icon{
    color:rgb(35, 109, 62)
}

.orange-icon{
    color:rgb(184, 112, 35);
}

.sea-blue-icon{
    color:rgb(34, 171, 181);
}

.purple-icon{
    color:rgb(109, 35, 109);
}

.about-course{
    padding:30px
}

hr{
    margin:30px 0;
    margin-top:60px;
}

.star{
    color:rgb(255, 204, 0)
}

.no-border{
    border:none !important;
}

.dark-bg{
    background:#073B43;
}