.upload-wrapper{
    background:rgb(252, 252, 252) !important;
    border:solid 1px rgb(220, 220, 220);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    border-radius: 5px;
    position: relative;
    /* height: 85%; */
    padding:15px 0;
}
.upload-wrapper p {
    margin-bottom: 0;
    font-size:12px;
    color:gray;
    text-align: center;
}

.upload-wrapper img{
    width: 40% !important;
}

.upload-wrapper .remove-icon{
    position: absolute;
    top:5px;
    right:5px;
    background:rgb(165, 53, 53);
    width:20px;
    height: 20px;
    color:white;
    display: flex;
    align-items: center;justify-content: center;
    border-radius: 20px;
    font-size:12px;
    cursor: pointer;
}

.primary-btn-full{
    width:100%;
    margin-top:10px;
    background:rgb(33, 138, 33) !important;
    color:white !important;
}
.primary-btn-full-disabled{
    width:100%;
    margin-top:10px;
    background:rgb(128, 162, 128) !important;
    color:rgb(26, 109, 26) !important;
}
.primary-btn-full:hover{
    background:rgb(26, 109, 26) !important;
}

.loading-text{
    position: absolute;
    top:20%;
    left:45%;
    color:white;
}

.progress-bar div:first-child{
background:#208a21;
}

.progress-bar{
    background:#83b283;
    border-radius: 3px;
}