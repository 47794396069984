.file-wrapper{
    padding:10px;
    background-color: #e7e7e7 !important;
}

.file-name{
    margin-left:10px
}

.file-inner{
    display: flex;
    align-items:center;
    background:rgb(247, 247, 247);
    margin:5px 0;
}

.doc-name{
    background:rgb(227, 227, 227);
    padding:10px;
    margin-bottom: 5px;
    border-radius:5px;
    margin-top:0;
}

.no-scroll{
    overflow: hidden;
}

.scroll-y{
    overflow-y: scroll;
}

.file-wrapper.doc{
    cursor: pointer;
}

.file-wrapper.doc img{
    width:13% !important;
}
.file-wrapper.doc p{
    width:83% !important;
}

.requestField{
    width:100%;
}

.req-dialog h5{
    margin:0;
    padding:10px;
    border:solid 1px #cbcbcb;
}

.req-dialog h5:nth-child(2){
    border-top:none;
    border-bottom: none;
}

.req-dialog h5:nth-child(3){
margin-bottom:10px;
}

.btns-wrapper{
    display: flex;
    align-items:center;
    
}

.slide-wrapper{
    border:solid;
    border-width:1px;
    border-color:rgb(201, 201, 201);
    border-radius:10px;
    background:rgb(225, 225, 225);
    /* padding:5px !important; */
    overflow: hidden;
    display: flex;
    align-items:center;
    justify-content:center;
    height:150px !important;
    position: relative;
}
.slide-wrapper img{
    width:100%;
}

.upload-slider-btn{
    background:rgb(26, 103, 67) !important;
    color:white !important
}
.upload-slider-btn-deactivate{
    background:rgb(121, 154, 138) !important;
    color:white !important
}

.delete-slide-btn{
    font-size: 14px !important;
    width:18px !important;
    height:18px !important;
    display: flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    top:5px;
    right:5px;
    background:rgb(171, 52, 52);z-index:50;
    border-radius: 20px;
    cursor: pointer;
    color:white;
}

.my-shop-container{
    background:white;
    border-radius: 10px;
    border:solid 2px rgb(232, 232, 232);
}

.container-wrapper{
    background:white;
    padding:20px;
}

.page-title{
    margin:18px 0;
}

.header-row{
    background:#E2EDEF;
    padding:10px;
    margin-bottom: 15px !important;
    border-radius: 5px;
}

.add-fields-wrapper{
    display: flex;
}

.add-field{
    width:90%;
    background:white;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.add-btn{
    width:10% !important;

}

.add-field input{
    padding:10px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.add-field fieldset{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right:none !important;
}
.add-btn{
    /* width:100% !important; */
    background:white !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height:100%;
    border:solid 1px #c4c4c4 !important;
    color:#33b377 !important;
    border-left:none !important;
}
.add-subject-btn{
    width:100% !important;
    background:#33b377 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height:100%;
    color:#fff !important;
    border-left:none !important;
    font-weight: 500 !important;
}

.add-wrapper{
    margin-bottom: 10px;
    margin-top:10px
}

.table-row {
    margin: 5px 0 !important;
    padding: 0 5px;
    
}

.table-row-subject{
    background: #f2fafc;
    height: 95%;
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #a6ccd3;
}

.inner-row{
    background:white;
    border: solid 1px #1A95AC;
    padding:5px;
    border-radius: 5px;
}
.inner-row-subject{
    background:#1A95AC;
    border: solid 1px #1A95AC;
    padding:5px;
    border-radius: 5px;
    color:white;
    font-weight: 500 !important;
}



.lite-text{
 font-weight: 300 !important;
}
.lite-text{
 font-weight: 300 !important;
}

.center{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color:rgb(186, 69, 69);
}

.notification-badge .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(0.5) translate(50%, -50%) !important;
}

.delete-icon{
    font-size:18px !important;
    cursor:  pointer !important;
}

.white-icon{
    color:white !important;
    cursor: pointer;
}

.margin-bottom{
    /* margin:10px;
    padding:5px; */
}

.sortable-wrapper{
    display: flex;
    flex-wrap: wrap;
    width:100%;
}

.hide-progress{
    display: none !important;
}

.white-ring{
    color:white
}


.waiting-wrapper{
    position: absolute;
    width:100%;
    top:0;
    left:0;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    background:#ffffffad !important;
}

.waiting-wrapper h3{
    color:black
}

.deleting-ring{
    color:rgb(167, 48, 48) !important;
    margin-bottom: 20px;
}