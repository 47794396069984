.text-center{
    text-align:center
}
.heading-primary{
    color:#013741;
}
.round-corner-card{
    border-radius: 10px;
    padding:15px;
}
.heading-sm{
    font-weight: 300 !important;
    font-size:20px !important;
}
.brand-logo-sm{
    width:30%;
    margin-bottom: 10px;
}
.card-content-center{
    justify-content: center;
    align-items:center;
    padding:10px !important;
}
.d-flex-column{
    display: flex;
    flex-direction: column;
}
.form-wrapper{
    margin-top: 20px;
}
.primary-btn{
margin-bottom: 0 !important;
}

.form-error-message{
    color:red;
    margin-top:5px;
}

.heading-xs{
    font-weight: 500 !important;
    font-size:16px !important;
}

.text-danger{
    color:red
}
.text-success{
    color:rgb(0, 155, 0)
}

.MuiCardMedia-img {
    object-fit: cover;
    object-position: 30%;
}

.wallet-btn-wrapper{
    margin:0 20px;
}

.right-sidebar-scroller::-webkit-scrollbar{
    display: none;
}