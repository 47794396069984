.mobile-calendar .fc-event-time {
    display: none;
}

.mobile-calendar .fc-event-title {
    display: none;
}

.mobile-calendar .fc-daygrid-event-dot {
    width: 0px;
    height: 0px;
    /* border-width: 4px !important; */
    /* font-size: 21px !important; */
}

.mobile-calendar .fc-header-toolbar.fc-toolbar {
    display: flex;
    flex-direction: column;
}

.mobile-calendar .fc-toolbar-title {
    font-size: 22px !important;
    margin-bottom: 10px !important;
}

.mobile-calendar .fc-button-group {
    margin-bottom: 10px !important;
}

.mobile-calendar .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-bottom: 0 !important;
}