.profile-page{
    min-height: 92vh;
    background-color: rgb(242, 242, 242);
}

.cover-bg{
    background-image: url('cover.jpg');
    background-size:contain;
    height: 250px;
}

.pic-wrapper{
    width:150px;
    height: 150px;
    background:white;
    border:solid;
    border-width: 1.5px;
    border-radius: 120px;
    overflow: hidden;
}

.basic-info-wrapper{
    margin-top: -4rem;
}

.basic-info{
    margin-left:10px;
    margin-bottom:10px !important;
}

.left-wrapper{
    display: flex;
    align-items:flex-end;
}

.right-wrapper{
    margin-top:4rem
}

.other-info-wrapper{
    margin-top:5rem
}

.border-outline{
    border-width: 1px;
    padding:15px;
    border-radius:10px;
    background:white;
}

.profile-tabs{
    margin-top:30px !important;
    margin-bottom:60px !important;
}

.profile-bar{
    background:#1a95ac !important;
}

.indicator{
    background-color:#157384 !important;
}


.success-message{
    margin: 10px 0 !important;
}

.country-selector button{
    padding:14px 10px;
}