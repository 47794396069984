.single-level-btn{
    background:blue;
}

.single-level-btn span input{
    border:none !important;
    color:transparent;
}

.level-btn{
    margin: 3px !important;
}

.subjects-wrapper{
    display:flex;
    align-items:center;
}

.subjects-wrapper div {flex: 1 0;}

.subjects-wrapper div button {
    width: 100%;
    margin: 5px 0 !important;
    color: #ea8b11;
    border-color: #ea8b11;
    margin-bottom: 7px !important;
}

.subjects-wrapper{
    margin: 0 !important;
    padding: 0 4px !important;
}

.subjects-wrapper .MuiToggleButton-root.Mui-selected:hover,.subjects-wrapper .MuiToggleButton-root.Mui-selected{
    color: #ffff;
    border-color: #ea8b11;
    background-color: #ea8b11;
}

.p-heading{
    margin-left:0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    display: flex;
    align-items: start;
    font-weight: 500;
    font-size: 20px;
    color:rgb(152, 152, 152);
}
.p-level-heading{
    margin-left:5px !important;
    margin-bottom: 0px !important;
    margin-top: 10px !important;
    display: flex;
    align-items: start;
    font-weight: 500;
    font-size: 20px;
    color:rgb(152, 152, 152);
}
.p-sub-heading{
    margin-left:7px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    display: flex;
    align-items: start;
    font-weight: 500;
    font-size: 16px;
    color:rgb(152, 152, 152);
}
.p-sub-heading span{
    font-size: 18px;
}
.p-heading span,.p-sub-heading span{
    margin-right:5px;
}

.filter-drawer{
    position: relative;
}

.collapse-drawer{
    margin-top:10px;
    display: flex;
    width:100%;
    justify-content:flex-end;
}
.collapse-drawer button{
    background:#cfcfcf;
    width:40px !important;
    height: 40px !important;
    border-radius: 40px;
    min-width: 10px;
}

.bottom-bar .user-avatar{
    border-radius: 30px;
}

.bottom-bar{
    background:rgb(220, 220, 220);
    padding:5px 0px;
    position:fixed;
    bottom:0;
    width:100%;
}

.bottom-bar img {
    width: 45%;
}

.bottom-bar .avatar {
    width: 65%;
}

.filters-wrapper.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 35%);
    z-index:20 !important;
}

.filter-header{
    padding:10px 5px;
    margin-top:7px;
}

.expand-more-button{
    background:rgb(218, 218, 218) !important;

}

.expand-more-button .material-icons{
    margin-left:5px;
}

.follow-button{
    background-color:#F69A24 !important;
    margin-right:10px !important;
    color:white !important;
}