.static-page-header-wrapper{
    background:#0c4754;
    padding:2rem
}

.text-white{
    color:white;
}

.static-content-wrapper{
    padding:20px;
    margin:20px 0;
    background:white;
}

.sort-button{
    border-radius:30px;
    /* min-width:20px; */
    /* width:35px !important; */
    /* height:35px !important; */
    background-color:rgb(218, 218, 218) !important;
    /* display: flex;
    align-items: center;
    justify-content:center; */
}

.course-filters{
    /* right:0 !important; */
    top:30px !important; 
    position: absolute;
}

.courseFilter{
    background:white;
    border:solid 1px rgb(233, 233, 233);
    border-radius:5px;
    box-shadow: 2px 2px 4px rgb(220, 220, 220);
}

.filter-btn{
    min-width:0 !important;
    border-radius:30px !important;
    font-size:20px !important;
    padding:5px !important;
    background:rgb(242, 242, 242) !important;
}
.filter-btn:hover{
    background:rgb(233, 233, 233) !important;
}
.sort-icon{
    margin-right:5px;
    font-size:18px !important;
}

span.order-count {
    width: 40px;
    height: 40px;
    background: #1a95ac;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    border-radius: 30px;
    color: white;
}

.help-icons {
    background: white;
    border: solid 1px #e7e7e7;
    border-radius: 5px;
    box-shadow: 2px 2px 6px #0000001f;
}

.how{
    margin-bottom:20px;
}

.order-count{
    margin-top:-20px;
    margin-bottom:30px;
    margin-left:10px;
}

.how .order-count{
    margin-top: 20px;
    margin-bottom:30px;
    margin-left:20px;
}

.help-card-inner {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 30px;
}

.primary-head{
    color:#ef8f14;
}

html {
    scroll-behavior: smooth;
}

a.top-nav {
    text-decoration: none;
    background: #e9e9e9;
    margin: 0 10px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 0px 15px;
}

.help-icons{
    height:100%;
}