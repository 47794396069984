.time-Btn {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  gap: 10px;
}
.calendar {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  column-gap: 90px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.lessons-collapsable-section .ant-collapse-header {
  display: flex !important;
  align-items: center !important;
  background: #e6faf8;
  padding: 5px 15px !important;
  color: #01cbb9 !important;
}
