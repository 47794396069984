a.fc-daygrid-event.custom {
    background: #eae2d7;
    color: black;
}

a.fc-daygrid-event.custom:hover {
    background: #d5cdc1;
}

.desktop-calendar a{
    color:black !important;
}